import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import HavePermission from '../PermissionsService/HavePermission';
import { checkUpdatePermission, checkDeletePermission } from '../PermissionsService/PermissionsService';

const TableRow = props => {
    const [updatePermission, setUpdatePermission] = useState(null);
    const [deletePermission, setDeletePermission] = useState(null);

    const { pathname } = props.location;
    const rowData = { ...props.data };

    useEffect(() => {
        const updateResult = checkUpdatePermission(pathname);
        setUpdatePermission(updateResult);

        const deleteResult = checkDeletePermission(pathname);
        setDeletePermission(deleteResult);
    }, [pathname]);

    const styleConditioner = (item) => {
        if (item === rowData.id || item === rowData.permissions || item === rowData.suspenseAccount || item === rowData.interbankSuspenseAccount || item === rowData.p2BSuspenseAccount || item === rowData.p2BInterbankSuspenseAccount || item === rowData.timeClosesOn || item === rowData.bank) {
            return {
                display: 'none'
            }
        } else {
            return null;
        }
    };

    const displayLockUnlock = (lockStatus, userStatus) => {
        if (lockStatus === "Unlocked") {
            return <Dropdown.Item style={itemStyle} onClick={props.lockClick}>Lock</Dropdown.Item>;
        } else if (lockStatus === "Locked" && userStatus !== "New" && userStatus !== "Pending") {
            return <Dropdown.Item style={itemStyle} onClick={props.unlockClick}>Unlock</Dropdown.Item>;
        }
    }

    return (
        <React.Fragment>
            <Table.Row
                onClick={pathname === '/users' ? props.onClick : pathname === '/error-management' && updatePermission === 'error.management.edit.error.translations' ? props.editClick : null}
                style={{ cursor: 'pointer' }}
                className={props.class}
            >
                {pathname !== '/rules' ? Object.values(rowData).map((item, index) => {
                    return (
                        <Table.Cell key={index} style={styleConditioner(item)}>
                            {pathname === '/users' && item === rowData.roles ? rowData.roles.map((role, index) => index !== rowData.roles.length - 1 ? role + ', ' : role) : item}
                        </Table.Cell>
                    );
                }) : null}
                {pathname !== '/message-history' && pathname !== '/error-management' ? (
                    <Table.Cell>
                        <Dropdown pointing icon='ellipsis vertical'>
                            <Dropdown.Menu style={pathname === '/users' ? userDropdownStyle : dropdownStyle}>
                                {rowData.status !== 'Pending' ? (
                                    <HavePermission for={updatePermission}>
                                        <Dropdown.Item style={itemStyle} onClick={props.editClick}>Edit</Dropdown.Item>
                                    </HavePermission>
                                ) : null}
                                <HavePermission for={deletePermission}>
                                    <Dropdown.Item style={itemStyle} onClick={props.deleteClick}>{pathname === '/system-alerts' ? 'Deactivate' : 'Delete'}</Dropdown.Item>
                                </HavePermission>
                                {pathname === '/users' ? (
                                    <React.Fragment>
                                        {rowData.status === 'Pending' || rowData.status === 'New' ? (
                                            <HavePermission for="manage.users.view">
                                                <Dropdown.Item style={itemStyle} onClick={props.viewDetails}>View Details</Dropdown.Item>
                                            </HavePermission>
                                        ) : null}
                                        {rowData.status !== "Pending" ? (
                                            <React.Fragment>
                                                <HavePermission for="manage.users.lock.unlock">
                                                    {displayLockUnlock(rowData.isLocked, rowData.status)}
                                                </HavePermission>
                                                <HavePermission for="manage.users.clear.unsuccessful.login.attempts">
                                                    <Dropdown.Item style={itemStyle} onClick={props.clearAttemptsClick}>Clear  Attempts</Dropdown.Item>
                                                </HavePermission>
                                            </React.Fragment>
                                        ) : null}
                                    </React.Fragment>
                                ) : null}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Table.Cell>
                ) : null}
            </Table.Row>
        </React.Fragment>
    );
}

const dropdownStyle = {
    marginLeft: '-35px'
}

const userDropdownStyle = {
    marginLeft: '-60px'
}

const itemStyle = {
    fontSize: '.7rem'
}

TableRow.propTypes = {
    data: PropTypes.object.isRequired,
};

export default withRouter(TableRow);