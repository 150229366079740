import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination, Divider, Icon } from 'semantic-ui-react';
import { PageSizeSelected } from './PageSizeSelected.jsx';
import TableRow from './TableRow.jsx';
import TableHeader from './TableHeader.jsx';
import Sidemenu from "../UI/Sidemenu";
import Spinner from "../UI/Spinner";
import FilterInputs from '../Filters/FilterInputs';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

const CustomTable = props => {
    const [visible, setVisible] = useState(false);
    const [user, setUser] = useState(null);
    const [activeRow, setActiveRow] = useState(null);

    const { pathname } = props;

    const onRowClickHandler = (data) => {
        setVisible(true);
        setUser(data);
        setActiveRow(data.id);
    };

    const onToggleClick = () => {
        setVisible(!visible);
    }

    if (!props.data) {
        return <React.Fragment />;
    }

    const viewDetailsHandler = (data) => {
        props.parentCallback(data, 'viewDetails');
    };

    const deleteClickHandler = (data) => {
        props.parentCallback(data, 'delete');
    };

    const editClickHandler = (data) => {
        props.parentCallback(data, 'update');
    };

    const lockClickHandler = (data) => {
        props.parentCallback(data, 'lock');
    };

    const clearAttemptsClickHandler = (data) => {
        props.parentCallback(data, 'clearAttempts');
    };

    const unlockClickHandler = (data) => {
        props.parentCallback(data, 'unlock');
    };

    const rows = props.data.map((data, index) => (
        <TableRow
            key={index}
            data={data}
            visible={visible}
            viewDetails={() => viewDetailsHandler(data)}
            deleteClick={() => deleteClickHandler(data)}
            editClick={() => editClickHandler(data)}
            lockClick={() => lockClickHandler(data)}
            clearAttemptsClick={() => clearAttemptsClickHandler(data)}
            unlockClick={() => unlockClickHandler(data)}
            onClick={() => onRowClickHandler(data)} class={pathname === '/users' && data.id === activeRow ? 'pressed' : ''}
        />
    ));

    return (
        <Fragment>
            <SplitterLayout percentage secondaryInitialSize={20}>
                <div style={visible || pathname !== '/users' ? { marginRight: '1.2rem' } : { marginRight: 45 }}>
                    <FilterInputs
                        pathname={props.pathname}
                        filters={props.filters}
                        loading={props.loading}
                        onRefreshHandler={props.refreshHandler}
                        onResetFilters={props.onResetFilters}
                    />
                    {pathname !== '/permissions' ? (
                        <Fragment>
                            <div className={pathname === '/system-alerts' ? 'table-overflow system-alerts-table' : pathname === '/users' ? null : 'table-overflow'}>
                                <Table singleLine={pathname !== '/users' ? true : false} celled sortable unstackable selectable>
                                    <TableHeader
                                        data={props.data}
                                    />
                                    <Table.Body>{props.loading ? <div className="spinner-container"><Spinner /></div> : rows}</Table.Body>
                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan="22" style={{ borderRadius: 0 }}>
                                                <Pagination
                                                    size='mini'
                                                    totalPages={props.totalPages}
                                                    activePage={props.currentPage}
                                                    onPageChange={props.onChangePage}
                                                    disabled={props.totalCount <= 0 ? true : false}
                                                    style={{ borderRadius: 0, fontSize: 15 }}
                                                />
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                            <Divider />
                            <div className="text-right">
                                <PageSizeSelected
                                    limit={props.limit}
                                    onChangeLimit={props.onChangeLimit}
                                />
                                Total count: {props.totalCount}.
                            </div>

                        </Fragment>
                    ) : null}
                </div>

                {visible && (
                    <div>
                        <SplitterLayout percentage secondaryInitialSize={90}>
                            <div className="collapser2" onClick={onToggleClick}>
                                <Icon name='caret right' />
                            </div>
                            <div>
                                <Sidemenu
                                    visible={visible}
                                    user={user}
                                    onToggleClick={onToggleClick}
                                />
                            </div>
                        </SplitterLayout>
                    </div>
                )}
            </SplitterLayout>

            {pathname === '/users' ? (
                <div className={`collapser ${visible ? 'toggled' : ''}`} onClick={onToggleClick}>
                    {visible ? <Icon name='caret right' /> : <Icon name='caret left' />}
                </div>
            ) : null}
        </Fragment>
    );
};

CustomTable.propTypes = {
    totalCount: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeLimit: PropTypes.func.isRequired,
    limit: PropTypes.string.isRequired,
};

export default CustomTable;
