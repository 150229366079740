import React, { Component } from 'react';
import config from '../config';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider, Form, Select } from "semantic-ui-react";
import Dialog from '../components/UI/Dialog';
import countryList from 'react-select-country-list'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { displayErrors } from '../helper';

class MobileOperator extends Component {
    constructor(props) {
        super(props);
        this.options = countryList().getData();
        this.state = {
            id: '',
            name: '',
            code: '',
            isOpen: false,
            isUpdateDialog: false,
            isDeleteDialog: false,
            isLoading: false,
            options: this.options,
            country: ''
        };

        this.callbackFunction = this.callbackFunction.bind(this);
        this.createMNOHandler = this.createMNOHandler.bind(this);
        this.createNewMNOHandler = this.createNewMNOHandler.bind(this);
        this.updateMNOHandler = this.updateMNOHandler.bind(this);
        this.deleteMNOHandler = this.deleteMNOHandler.bind(this);
    }

    callbackFunction(childData, identifier) {
        this.setState({ id: childData.id });
        this.setState({ name: childData.name });
        this.setState({ code: childData.code });
        this.setState({ country: childData.country });

        if (identifier === 'update') {
            this.setState({ isUpdateDialog: true });
        } else if (identifier === 'delete') {
            this.setState({ isDeleteDialog: true });
        }
    };

    createMNOHandler() {
        this.setState({ isOpen: true });
    };

    createNewMNOHandler(values) {
        const { name, code } = values;

        this.setState({ isLoading: true });
        fetch(`${config.adminBaseURL}/mobilenetworkoperator/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "name": name,
                "code": code,
                "country": this.state.country
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ isLoading: false });
            if (data.success) {
                this.setState({ isOpen: false });
                this.setState({ name: '' });
                this.setState({ code: '' });
                toast.success('MNO created successfully!');
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        })
    };

    updateMNOHandler(values) {
        const { id } = this.state;
        const { name, code, country } = values;

        this.setState({ isLoading: true });
        fetch(`${config.adminBaseURL}/mobilenetworkoperator/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": id,
                "name": name,
                "code": code,
                "country": country
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ isLoading: false });
            if (data.success) {
                this.setState({ isUpdateDialog: false });
                this.setState({ id: '' });
                this.setState({ name: '' });
                this.setState({ code: '' });
                toast.success('MNO updated successfully!');
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        })
    }

    deleteMNOHandler() {
        this.setState({ isLoading: true });
        fetch(`${config.adminBaseURL}/mobilenetworkoperator/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": this.state.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ isLoading: false });
            if (data.success) {
                this.setState({ isDeleteDialog: false });
                this.setState({ id: '' });
                this.setState({ name: '' });
                this.setState({ code: '' });
                toast.success('MNO deleted successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    }

    countryChangeHandler(e, data) {
        this.setState({ country: data.value });
    }

    render() {
        const { name, code, country, isOpen, isUpdateDialog, isLoading, isDeleteDialog, options } = this.state;

        return (
            <React.Fragment>
                <HeaderContent onClickHandler={this.createMNOHandler} buttonTitle={'Create New MNO'} showButton={true} />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} />

                <Formik
                    initialValues={{ name: name, code: code, country: '' }}
                    onSubmit={(values, { resetForm }) => {
                        this.createNewMNOHandler(values);
                        resetForm();
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('This field is required.'),
                        code: Yup.string()
                            .required('This field is required.')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={isOpen}
                            onCancel={() => {
                                this.setState({ isOpen: false });
                            }}
                            onConfirm={handleSubmit}
                            title={'Create New MNO'}
                            confirmText={'Create MNO'}
                            loading={isLoading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.name && touched.name ? (
                                            <span className='formik-error-msg'>{errors.name}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Code" type='text' name='code' placeholder='Code' value={values.code} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.code && touched.code ? (
                                            <span className='formik-error-msg'>{errors.code}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal' className='mno'>
                                    <Form.Field>
                                        <label>Country</label>
                                        <Select
                                            options={options.map(opt => {
                                                return { key: opt.value, value: opt.label, text: opt.label }
                                            })}
                                            onChange={this.countryChangeHandler.bind(this)}
                                            style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        {country === '' && touched.country ? (
                                            <span className='formik-error-msg'>This field is required.</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Formik
                    enableReinitialize
                    initialValues={{ name: name, code: code, country: country }}
                    onSubmit={values => {
                        this.updateMNOHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('This field is required.'),
                        code: Yup.string()
                            .required('This field is required.')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={isUpdateDialog}
                            onCancel={() => {
                                this.setState({ isUpdateDialog: false });
                                this.setState({ id: '' });
                                this.setState({ name: '' });
                                this.setState({ code: '' });
                                this.setState({ country: '' });
                            }}
                            onConfirm={handleSubmit}
                            title={'Update MNO'}
                            confirmText={'Update MNO'}
                            loading={isLoading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={(e) =>{
                                            this.setState({ name: e.target.value });
                                        }} onBlur={handleBlur} />
                                        {errors.name && touched.name ? (
                                            <span className='formik-error-msg'>{errors.name}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Code" type='text' name='code' placeholder='Code' value={values.code} onChange={(e) =>{
                                            this.setState({ code: e.target.value });
                                        }} onBlur={handleBlur} />
                                        {errors.code && touched.code ? (
                                            <span className='formik-error-msg'>{errors.code}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Country</label>
                                        <Select
                                            value={country}
                                            options={options.map(opt => {
                                                return { key: opt.value, value: opt.label, text: opt.label }
                                            })}
                                            onChange={this.countryChangeHandler.bind(this)}
                                            style={{ borderRadius: 0, height: 50}} />
                                        {country === '' && touched.country ? (
                                            <span className='formik-error-msg'>This field is required.</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Dialog
                    open={isDeleteDialog}
                    onCancel={() => {
                        this.setState({ isDeleteDialog: false });
                        this.setState({ id: '' });
                        this.setState({ name: '' });
                        this.setState({ code: '' });
                        this.setState({ country: '' });
                    }}
                    onConfirm={this.deleteMNOHandler}
                    title={'Delete MNO'}
                    confirmText={'Delete MNO'}
                    loading={isLoading}
                ><p>Are you sure you want to delete this mno?</p></Dialog>

                <ToastContainer />
            </React.Fragment>
        );
    }
};

export default MobileOperator;