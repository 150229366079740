import React, { useState, useEffect } from 'react';
import { Table, Dropdown } from 'semantic-ui-react';
import config from '../../config';
import Spinner from '../UI/Spinner';
import HavePermission from '../PermissionsService/HavePermission';
import { checkUpdatePermission } from '../PermissionsService/PermissionsService';

const RulesTable = props => {
    const [rules, setRules] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [updatePermission, setUpdatePermission] = useState(null);

    const { data, pathname } = props;

    useEffect(() => {
        getCustomerCategoryList();
    }, []);

    useEffect(() => {
        setRules(data);
    }, [data]);

    useEffect(() => {
        const updateResult = checkUpdatePermission(pathname);
        setUpdatePermission(updateResult);
    }, [pathname]);

    const getCustomerCategoryList = () => {
        fetch(`${config.adminBaseURL}/category/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setCategoryList(data.value.data);
        })
    }

    const deleteClickHandler = (data) => {
        props.parentCallback(data, 'delete');
    };

    const editClickHandler = (data) => {
        props.parentCallback(data, 'update');
    };

    const displayNotNullableValues = (type, value) => {
        if (value !== null) {
            return `${type} ${value}`;
        }
        return;
    }

    const rows = rules.map(rule => (
        <Table.Row>
            <Table.Cell>{rule.priority}</Table.Cell>
            <Table.Cell><strong>Business Unit:</strong> {rule.channel === 0 ? 'KUIK General' : 'RBAL'} <br /><br /> <strong>Customer Category:</strong> {categoryList.map(category => {
                if (category.id === rule.customerCategoryId) {
                    return category.name;
                }
            })}</Table.Cell>
            <Table.Cell><strong>Operation: </strong> {rule.transferType === 1 ? 'P2P' : 'Balance Enquiry'}</Table.Cell>
            <Table.Cell>
                <strong>Currency: </strong> {rule.currency}
                <br /><br />
                {rule.minAmount !== null || rule.maxAmount !== null ? <strong>Transaction: </strong> : null} {displayNotNullableValues('min', rule.minAmount)} {displayNotNullableValues('max', rule.maxAmount)}
                {rule.minAmount !== null || rule.maxAmount !== null ? <span><br /><br /></span> : null}
                {rule.maxAmountLifetime !== null || rule.maxCountLifetime !== null ? <strong>Lifetime: </strong> : null} {displayNotNullableValues('max', rule.maxAmountLifetime)} {displayNotNullableValues('count', rule.maxCountLifetime)}
                {rule.maxAmountLifetime !== null || rule.maxCountLifetime !== null ? <span><br /><br /></span> : null}
                {rule.maxAmountDaily !== null || rule.maxCountDaily !== null ? <strong>Daily: </strong> : null} {displayNotNullableValues('max', rule.maxAmountDaily)} {displayNotNullableValues('count', rule.maxCountDaily)}
                {rule.maxAmountDaily !== null || rule.maxCountDaily !== null ? <span><br /><br /></span> : null}
                {rule.maxAmountWeekly !== null || rule.maxCountWeekly !== null ? <strong>Weekly: </strong> : null} {displayNotNullableValues('max', rule.maxAmountWeekly)} {displayNotNullableValues('count', rule.maxCountWeekly)}
                {rule.maxAmountWeekly !== null || rule.maxCountWeekly !== null ? <span><br /><br /></span> : null}
                {rule.maxAmountMonthly !== null || rule.maxCountMonthly !== null ? <strong>Monthly: </strong> : null} {displayNotNullableValues('max', rule.maxAmountMonthly)} {displayNotNullableValues('count', rule.maxCountMonthly)}
                <br /><br />
            </Table.Cell>
            <Table.Cell>
                <Dropdown pointing icon='ellipsis vertical'>
                    <Dropdown.Menu style={dropdownStyle}>
                        <HavePermission for={updatePermission}>
                            <Dropdown.Item style={itemStyle} onClick={() => editClickHandler(rule)}>Edit</Dropdown.Item>
                        </HavePermission>
                        <Dropdown.Item style={itemStyle} onClick={() => deleteClickHandler(rule)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Table.Cell>
        </Table.Row>
    ));

    return (
        <Table singleLine celled sortable unstackable selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Priorty</Table.HeaderCell>
                    <Table.HeaderCell>Channel</Table.HeaderCell>
                    <Table.HeaderCell>Operation</Table.HeaderCell>
                    <Table.HeaderCell>Limit</Table.HeaderCell>
                    <Table.HeaderCell
                        width={1}
                    >
                        Actions
          </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {props.loading ? <div className="spinner-container"><Spinner /></div> : rows}
            </Table.Body>
        </Table>
    );
};

const dropdownStyle = {
    marginLeft: '-35px'
}

const itemStyle = {
    fontSize: '.7rem'
}

export default RulesTable;