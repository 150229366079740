import React from 'react';
import { withRouter } from 'react-router-dom';
import Table from './Table.jsx';
import RulesTable from './RulesTable';
import { list } from './list-data';
import config from '../../config.json';
import { connect } from 'react-redux';

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            limit: 10,
            totalCount: 0,
            totalPages: 0,
            loading: false,
            filters: []
        };
        this.onRefreshHandler = this.onRefreshHandler.bind(this);
    }

    componentDidMount() {
        this.loadData({});
        this.props.setRefresh(this.onRefreshHandler);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname === '/permissions' || this.props.location.pathname === '/roles') {
            if (prevState.data !== this.state.data) {
                this.sendPermissionData();
            }
        }
        if (prevProps.filters.filters !== this.props.filters.filters) {
            this.setState({ filters: this.props.filters.filters });
        }
    }

    callbackFunction = (childData, identifier) => {
        this.props.parentCallback(childData, identifier);
    };

    sendPermissionData = () => {
        this.props.getPermissionData(this.state.data);
    };

    onChangeLimit = (event, data) => {
        if (data.value !== this.state.limit) {
            this.setState({ limit: parseInt(data.value) })
            this.setState({ page: 1 });
            this.loadData({ limit: parseInt(data.value), page: this.state.page });
        }
    };

    onChangePage = (event, data) => {
        const { activePage } = data;
        if (activePage !== this.state.page) {
            this.setState({ page: activePage });
            this.loadData({ page: activePage, limit: this.state.limit });
        }
    };

    getFilterValue = (position) => {
        const { filters } = this.state;
        return filters.length > 0 ? filters[position].value !== '' ? filters[position].value : null : null;
    }

    getFilterForStatus = (position, option) => {
        const { filters } = this.state;
        return filters.length > 0 && filters[position].options !== null ? filters[position].options[option].isClicked == true ? true : filters[position].options[option - 1].isClicked == true ? false : null : null;
    }

    getFilterForChannel = (position, option) => {
        const { filters } = this.state;
        if (filters.length > 0 && filters[position].options !== null) {
            if (filters[position].options[option].isClicked) {
                return true;
            }
        }
    }

    getUserFilterForStatus = (position, option) => {
        const { filters } = this.state;
        if (filters.length > 0 && filters[position].options !== null) {
            if (filters[position].options[option].isClicked) {
                return 3;
            } else if (filters[position].options[option - 1].isClicked) {
                return 2;
            } else if (filters[position].options[option - 2].isClicked) {
                return 1;
            } else if (filters[position].options[option - 3].isClicked) {
                return 0;
            }
        }
    }

    loadData = params => {
        const { pathname } = this.props.location;

        const baseOptions = {
            "pageIndex": params.page - 1,
            "pageSize": params.limit
        };

        let url;
        let otherOptions = {};

        list.map(listItem => {
            if (listItem.pathname === pathname) {
                otherOptions = listItem.apiBody;
                if (pathname === '/message-history') {
                    url = config.baseURL + listItem.endpoint;
                } else {
                    url = config.adminBaseURL + listItem.endpoint;
                }
                Object.keys(listItem.apiBody).map((opt, index) => {
                    if (pathname === '/system-alerts') {
                        if (opt === "eventType") {
                            listItem.apiBody[opt] = this.getFilterValue(index);
                        } else if (opt === "status") {
                            listItem.apiBody[opt] = this.getFilterForStatus(index, 1);
                        } else if ((opt === "channelPush" || opt === "channelEmail" || opt === "channelSms")) {
                            listItem.apiBody[opt] = this.getFilterForChannel(0, index - 2);
                        }
                    }

                    if (pathname === '/users') {
                        if (opt === "username") {
                            listItem.apiBody[opt] = this.getFilterValue(index);
                        } else if (opt === "status") {
                            listItem.apiBody[opt] = this.getUserFilterForStatus(index, 3);
                        } else if (opt === "isLocked") {
                            listItem.apiBody[opt] = this.getFilterForStatus(index, 1);
                        }
                    }

                    if (pathname === '/permissions') {
                        if (opt === "name") {
                            listItem.apiBody[opt] = this.getFilterValue(index);
                        }
                    }
                });
            }
        })

        let options = Object.assign({}, otherOptions, baseOptions);

        this.setState({ loading: true });
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(options)
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data.success) {
                if (pathname === '/users') {
                    data.value.data.map(el => {
                        if (el.isLocked) {
                            el.isLocked = 'Locked';
                        } else {
                            el.isLocked = 'Unlocked';
                        }
                    })
                };
                if (pathname === "/rules") {
                    this.setState({ data: data.value });
                } else {
                    this.setState({ data: data.value.data });
                }
                this.setState({ totalCount: data.value.totalCount });
                this.setState({ totalPages: data.value.totalPages });
            } else {
                console.log(`Failed to load data`);
            }
            this.setState({loading: false});
        });
    };

    onRefreshHandler = () => {
        this.loadData({ page: 1 });
    }

    onResetFilters = () => {
        const updatedFilters = [...this.state.filters];
        updatedFilters.map(filter => {
            return filter.value = '';
        })
        this.setState({ filters: updatedFilters }, this.loadData({ page: 1 }));
    }

    render() {
        let { pathname } = this.props.location;

        this.state.data.map(item => {
            if (pathname === '/bank-management' || pathname === '/mno-management' || pathname === '/customer-category' || pathname === '/roles' || pathname === '/legal-forms' || pathname === '/commission-schemes') {
                delete item.createdOnUtc
                delete item.updatedOnUtc
                delete item.createdBy
                delete item.updatedBy
            }
            if (pathname === '/users') {
                delete item.bankBranch;
            }
            if (pathname === '/customer-category') {
                delete item.isStatic;
            }
            if (pathname === '/bank-management') {
                item['cobDuration'] = '';
            }
        })

        return (
            <div>
                {pathname === '/rules' ? (
                    <RulesTable
                        data={this.state.data}
                        loading={this.state.loading}
                        pathname={this.props.location.pathname}
                        parentCallback={this.callbackFunction}
                    />
                ) : (
                        <Table
                            onChangePage={this.onChangePage}
                            onChangeLimit={this.onChangeLimit}
                            data={this.state.data}
                            totalCount={this.state.totalCount}
                            totalPages={Math.ceil(this.state.totalCount / this.state.limit)}
                            currentPage={this.state.page}
                            limit={this.state.limit.toString()}
                            loading={this.state.loading}
                            refreshHandler={this.onRefreshHandler}
                            parentCallback={this.callbackFunction}
                            pathname={this.props.location.pathname}
                            onResetFilters={this.onResetFilters}
                            filters={this.state.filters}
                        />
                    )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    filters: state.filters,
})

export default connect(mapStateToProps, null)(withRouter(List));