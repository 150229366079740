import React, { Component, Fragment } from 'react';
import config from '../config';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider, Form } from "semantic-ui-react";
import Dialog from '../components/UI/Dialog';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { displayErrors } from '../helper';

class LegalForms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isUpdateDialog: false,
            isDeleteDialog: false,
            loading: false,
            id: '',
            name: ''
        }

        this.callbackFunction = this.callbackFunction.bind(this);
        this.createLegalForm = this.createLegalForm.bind(this);
        this.createNewLegalFormHandler = this.createNewLegalFormHandler.bind(this);
        this.updateLegalFormHandler = this.updateLegalFormHandler.bind(this);
        this.deleteLegalFormHandler = this.deleteLegalFormHandler.bind(this);
    };

    callbackFunction(childData, identifier) {
        this.setState({ id: childData.id });
        this.setState({ name: childData.name });

        if (identifier === 'update') {
            this.setState({ isUpdateDialog: true });
        } else if (identifier === 'delete') {
            this.setState({ isDeleteDialog: true });
        }
    };

    createLegalForm() {
        this.setState({ open: true });
    };

    createNewLegalFormHandler(values) {
        const { name } = values;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/legalform/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "name": name
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ open: false });
                this.setState({ name: '' });
                toast.success('Legal Form created successfully!');
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        });
    };


    updateLegalFormHandler(values) {
        const { id } = this.state;
        const { name } = values;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/legalform/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": id,
                "name": name
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ id: '' });
                this.setState({ name: '' });
                toast.success('Legal Form updated successfully!');
                this.setState({ isUpdateDialog: false });
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        })
    };


    deleteLegalFormHandler() {
        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/legalform/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": this.state.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ isDeleteDialog: false });
                this.setState({ id: '' });
                this.setState({ name: '' });
                toast.success('Legal Form deleted successfully!');
                this.refreshChild();
            } else {
                toast.error(data.messages[0].text);
            }
        })
    };

    render() {
        const { name, loading, open, isUpdateDialog, isDeleteDialog } = this.state;

        return (
            <Fragment>
                <HeaderContent onClickHandler={this.createLegalForm} buttonTitle={'Create New Legal Form'} showButton={true} />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} />

                <Formik
                    initialValues={{ name: name }}
                    onSubmit={(values, { resetForm }) => {
                        this.createNewLegalFormHandler(values);
                        resetForm();
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('This field is required.')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={open}
                            onCancel={() => {
                                this.setState({ open: false })
                            }}
                            onConfirm={handleSubmit}
                            title={'Create New Legal Form'}
                            confirmText={'Create Legal Form'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Field>
                                    <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.name && touched.name ? (
                                        <span className='formik-error-msg'>{errors.name}</span>
                                    ) : null}
                                </Form.Field>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Formik
                    enableReinitialize
                    initialValues={{ name: name }}
                    onSubmit={values => {
                        this.updateLegalFormHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('This field is required.')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={isUpdateDialog}
                            onCancel={() => {
                                this.setState({ isUpdateDialog: false })
                            }}
                            onConfirm={handleSubmit}
                            title={'Update Legal Form'}
                            confirmText={'Save'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Field>
                                    <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.name && touched.name ? (
                                        <span className='formik-error-msg'>{errors.name}</span>
                                    ) : null}
                                </Form.Field>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Dialog
                    open={isDeleteDialog}
                    onCancel={() => {
                        this.setState({ isDeleteDialog: false });
                        this.setState({ id: '' });
                        this.setState({ name: '' });
                    }}
                    onConfirm={this.deleteLegalFormHandler}
                    title={'Delete Legal Form'}
                    confirmText={'Delete Legal Form'}
                    loading={loading}
                ><p>Are you sure you want to delete this legal form?</p></Dialog>

                <ToastContainer />
            </Fragment >
        );
    }
};

export default LegalForms;