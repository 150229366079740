export const permissionsList = {
    updatePermissions: [
        {
            path: '/users',
            permission: 'manage.users.update'
        },
        {
            path: '/bank-management',
            permission: 'manage.banks.edit'
        },
        {
            path: '/system-alerts',
            permission: 'system.alert.edit'
        },
        {
            path: '/customer-category',
            permission: 'manage.customer.category.edit'
        },
        {
            path: '/mno-management',
            permission: 'manage.mno.update'
        },
        {
            path: '/roles',
            permission: 'manage.roles.update'
        },
        {
            path: '/rules',
            permission: 'manage.rules.update'
        },
        {
            path: '/error-management',
            permission: 'error.management.edit.error.translations'
        },
        {
            path: '/legal-forms',
            permission: 'manage.legal.forms.update'
        },
        {
            path: '/commission-schemes',
            permission: 'manage.commission.schema.update'
        }
    ],
    deletePermissions: [
        {
            path: '/users',
            permission: 'manage.users.delete'
        },
        {
            path: '/bank-management',
            permission: 'manage.banks.delete'
        },
        {
            path: '/customer-category',
            permission: 'manage.customer.category.delete'
        },
        {
            path: '/mno-management',
            permission: 'manage.mno.delete'
        },
        {
            path: '/roles',
            permission: 'manage.roles.delete'
        },
        {
            path: '/rules',
            permission: 'manage.rules.delete'
        },
        {
            path: '/system-alerts',
            permission: 'system.alert.deactivate'
        },
        {
            path: '/legal-forms',
            permission: 'manage.legal.forms.delete'
        },
        {
            path: '/commission-schemes',
            permission: 'manage.commission.schema.delete'
        }
    ],
    createPermissions: [
        {
            path: '/users',
            permission: 'manage.users.create'
        },
        {
            path: '/bank-management',
            permission: 'manage.banks.create'
        },
        {
            path: '/customer-category',
            permission: 'manage.customer.category.create'
        },
        {
            path: '/mno-management',
            permission: 'manage.mno.create'
        },
        {
            path: '/roles',
            permission: 'manage.roles.create'
        },
        {
            path: '/rules',
            permission: 'manage.rules.create'
        },
        {
            path: '/legal-forms',
            permission: 'manage.legal.forms.create'
        },
        {
            path: '/commission-schemes',
            permission: 'manage.commission.schema.create'
        }
    ]
}